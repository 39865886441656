import { define, dispatch, html, store } from "hybrids";
import {
  ConfirmCardPaymentData,
  StripePaymentRequestButtonElement,
} from "@stripe/stripe-js";

import Order from "~/stores/Order";
import PaymentMethods from "~/stores/PaymentMethods";
import PaymentRequest from "~/stores/PaymentRequest";

import { post } from "~/utils/api";
import load, * as stripe from "~/utils/stripe";

export interface PaymentEvent extends CustomEvent {
  detail: {
    paymentMethod: Order["paymentMethod"];
    paymentId: string;
  };
}

export interface VWebsiteStripe extends HTMLElement {
  settings: PaymentMethods["stripe"];
  paymentRequest?: PaymentRequest;
  cardEl?: ReturnType<typeof stripe.mount>;
  newCard: boolean;
  paymentId: string;
  error: string;
  pending: boolean;
}

async function confirm(
  host: VWebsiteStripe,
  event: Event | null,
  paymentMethod?: ConfirmCardPaymentData["payment_method"]
) {
  try {
    if (!host.paymentRequest) {
      throw Error("Missing payment request object");
    }

    host.error = "";

    if (!paymentMethod && !host.newCard && host.paymentId) {
      dispatch(host, "approve", {
        detail: { paymentMethod: "stripe", paymentId: host.paymentId },
      });
    } else {
      host.pending = true;

      const result = await stripe.confirmCardPayment(
        paymentMethod
      );

      if (result.error) {
        throw result.error.message || "Unknown error";
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === "succeeded"
      ) {
        host.paymentId = result.paymentIntent.id;
        host.pending = false;

        dispatch(host, "approve", {
          detail: { paymentMethod: "stripe", paymentId: host.paymentId },
        });
      }
    }
  } catch (error) {
    host.error = String(error);
    host.pending = false;

    throw error;
  }
}

function toggleNewCard(host: VWebsiteStripe) {
  host.newCard = !host.newCard;
}

export default define<VWebsiteStripe>({
  tag: "v-website-stripe",
  settings: {
    value: undefined,
    observe(host, value) {
      if (value && value.cards.length) {
        host.paymentId = value.cards[0].id;
      }
    },
  },
  paymentRequest: store(PaymentRequest),
  error: "",
  cardEl: {
    async get(host) {
      host.pending = true;
      if (!host.paymentRequest) {
        host.pending = false;
        throw Error("Missing payment request object");
      }

      const { key, account } = host.settings;
      const el = host.querySelector("#stripe-card-element") as HTMLElement;

      const paymentMethodsElement = await stripe.mount(el, key, account, host.paymentRequest.id, {
        colorText: window
          .getComputedStyle(host)
          .getPropertyValue("--v-ui-color-text-primary")
          .trim(),
        colorPrimary: window
          .getComputedStyle(host)
          .getPropertyValue("--v-ui-color-secondary")
          .trim(),
        colorBackground: window
          .getComputedStyle(host)
          .getPropertyValue("--v-ui-loader-background")
          .trim(),
      });

      setTimeout(() => {
        host.pending = false;
      }, 2000);

      return paymentMethodsElement;
    },
    connect: () => stripe.unmount,
    observe() {},
  },
  newCard: false,
  paymentId: "",
  pending: false,
  render: ({
    settings,
    newCard,
    paymentId,
    error,
    pending,
  }) => html`
    <v-ui-flexbox gap="medium">
      <v-ui-flexbox gap="small">
        ${!newCard &&
        !!settings.cards.length &&
        html`
          ${settings.cards.map(
            (card) => html`
              <label class="radio">
                <v-ui-input>
                  <v-ui-radio slot="header" size="small">
                    <input
                      type="radio"
                      name="card"
                      value="${card.id}"
                      checked="${card.id === paymentId}"
                      onChange="${html.set("cardId")}"
                    />
                  </v-ui-radio>
                  <v-ui-flexbox flow="row" gap="small">
                    <v-ui-text weight="semibold">${card.name}</v-ui-text>
                    <v-ui-flexbox grow="1"></v-ui-flexbox>
                    <v-ui-text color="text-secondary">
                      **** **** **** ${card.last4}
                    </v-ui-text>
                    <v-ui-text color="text-secondary">
                      ${card.expirationMonth}/${card.expirationYear}
                    </v-ui-text>
                  </v-ui-flexbox>
                </v-ui-input>
              </label>
            `
          )}

          <v-ui-button
            type="transparent"
            onclick="${toggleNewCard}"
            icon="plus"
            name="Add new card"
          >
          </v-ui-button>
        `}
        ${pending
          ? html`
            <v-ui-flexbox layout="center center" grow="1">
              <v-ui-icon name="spinner" color=""></v-ui-icon>
            </v-ui-flexbox>
          `
          : html`${(newCard || !settings.cards.length) &&
            html`
            <v-ui-input>
              <slot name="card"></slot>
            </v-ui-input>
            ${!!settings.cards.length &&
                  html`
              <v-ui-button
                type="transparent"
                onclick="${toggleNewCard}"
                icon="back"
                name="Use saved card"
              >
              </v-ui-button>
            `}
          `}
          <v-ui-button
            id="submit-button"
            name="Confirm"
            color="primary"
            pending=${pending}
            onclick="${confirm}"
          >
          </v-ui-button>
          ${error &&
                  html`<v-ui-text
            variant="caption"
            color="alert-danger"
            ygutter="1"
            selectable
          >
            ${error}
          </v-ui-text>`}
        </v-ui-flexbox>
      `}
    </v-ui-flexbox>
  `.css`
    :host(:last-child) v-ui-divider:last-child { display: none }
    
    label.radio {
      cursor: pointer;
    }
  `,
  content: () => html`
    <div id="stripe-card-element" slot="card"></div>
  `,
});
