import { define, dispatch, html, store } from "hybrids";
import { loadScript, PayPalNamespace } from "@paypal/paypal-js";
import { PayPalButtonsComponent } from "@paypal/paypal-js/types/components/buttons";

import { post } from "~/utils/api";
import PaymentRequest from "~/stores/PaymentRequest";

export interface WebsitePaypal {
  clientId: string;
  paymentRequest?: PaymentRequest;
  paymentId: string;
  error: string;
  el: PayPalButtonsComponent;
  paypal: Promise<PayPalNamespace | null>;
}

export default define<WebsitePaypal>({
  tag: "v-website-paypal",
  clientId: "",
  paymentRequest: store(PaymentRequest),
  paymentId: "",
  error: "",
  el: undefined,
  paypal: {
    get: ({ clientId, paymentRequest }) => {
      if (!paymentRequest) {
        return Promise.reject(Error("Missing payment request object"));
      }

      return loadScript({
        "client-id": clientId,
        currency: paymentRequest.currency,
        "disable-funding":
          "card,credit,blik,venmo,sepa,bancontact,eps,giropay,ideal,mybank,p24,sofort",
      });
    },
    observe(host, promise) {
      promise.then((paypal) => {
        if (paypal) {
          host.el?.close();

          host.el = paypal.Buttons?.({
            style: {
              layout: "vertical",
              color: "blue",
              shape: "rect",
              label: "paypal",
              height: 40,
            },
            createOrder: async () => {
              if (!host.paymentRequest) {
                throw Error("Missing payment request object");
              }

              host.error = "";

              const res = await post("/payment_methods/paypal", {
                id: host.paymentRequest.id,
              });

              if (res.errors) {
                throw res.errors;
              } else if (Math.round(res.status_code / 100) !== 2) {
                throw res.result.details[0].description;
              }

              return res.result.id;
            },
            onError: (error) => {
              host.error = String(error);
            },
            onApprove: async (data) => {
              host.error = "";
              host.paymentId = data.orderID;

              dispatch(host, "approve", {
                detail: { paymentId: data.orderID, paymentMethod: "paypal" },
              });
            },
          }) as PayPalButtonsComponent;

          host.el.render(host.querySelector("#paypal-input") as HTMLElement);
        }
      });
    },
  },
  render: ({ error }) =>
    html`
      <v-ui-flexbox><v-ui-divider>Integrated providers</v-ui-divider></v-ui-flexbox>
      <slot></slot>
      ${error &&
      html`
        <v-ui-text variant="caption" color="alert-danger" ygutter="1" selectable>
          ${error}
        </v-ui-text>
      `}
    `,
  content: ({ paymentId }) => html`
    <v-ui-button
      color="paypal"
      id="paypal-input"
      hidden="${paymentId}"
    ></v-ui-button>
    <v-ui-button
      color="paypal"
      name="Success"
      icon="check"
      hidden="${!paymentId}"
    ></v-ui-button>
  `,
});
