import { loadStripe } from "@stripe/stripe-js/pure";
import {
  ConfirmPaymentData,
  Stripe,
  StripeElement,
  StripeElements,
} from "@stripe/stripe-js";
import {post} from "~/utils/api";

let stripe: Stripe | null;
let paymentElement: StripeElement | null;
let elements: StripeElements | null;

export default async function load(key: string, stripeAccount: string) {
  if (stripe) return stripe;
  return (stripe = await loadStripe(key, { stripeAccount }));
}

export async function mount(
  target: HTMLElement,
  key: string,
  stripeAccount: string,
  paymentRequestId: string | null,
  styles?: object
) {
  if (!paymentRequestId) throw Error("Client secret not available");

  stripe = stripe || (await loadStripe(key, { stripeAccount }));
  if (stripe) {
    try {
      const stripeIntent = await post("/payment_methods/stripe", {
        id: paymentRequestId,
      });
      const clientSecret = stripeIntent.client_secret;
      const appearance = {
        theme: "stripe",
        variables: {
          fontFamily: "Averta, sans-serif",
          fontSizeBase: "14px",
          ...styles,
        }
      };
      elements = stripe.elements({ clientSecret, appearance });
      paymentElement = elements.create("payment", { layout: {
        type: "accordion",
        radios: false,
      }});

      paymentElement.mount(target);
    } catch (error) {
      throw error;
    }
  }

  return stripe;
}

export function unmount() {
  if (paymentElement) {
    paymentElement.unmount();
    paymentElement = null;
  }
}

export async function confirmCardPayment(
  paymentMethod?: ConfirmPaymentData["payment_method"]
) {
  if (!stripe) throw Error("Stripe API or Card element not initialized");

  return await stripe.confirmPayment({
    // paymentMethod || (paymentElement ? { paymentElement } : undefined),
    elements,
    confirmParams: {
      return_url: window.location.origin + "/payments/stripe/complete_payment"
    }
  });
}
