import { Model } from "hybrids";

interface PaymentRequest {
  id: string;
  amount: number;
  currency: string;
  country: string;
  label: string;
}

const PaymentRequest: Model<PaymentRequest> = {
  id: true,
  amount: 0,
  currency: "",
  country: "",
  label: "",
};

export default PaymentRequest;
